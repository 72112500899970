import React from 'react'
import Default from 'src/components/categoryPages/default'


const Homepage = ({ page, subpage, pageContext, products, mobile, location }) => {
  
  return (
    <Default pageContext={pageContext} page={page} subpage={false} products={products} mobile={mobile} location={location} />
  )

}
export default Homepage